import { Routes } from '@angular/router'
import { AppLayoutComponent } from './shared/layout/layout-home/app-layout.component'
import { LayoutLandingComponent } from './shared/layout/layout-landing/layout-landing.component'
import { authGuard } from './shared/guards/permission.guard'
import { inventoryRoutes } from './inventory/inventory.routes'
import { salesRoutes } from './sales/sales.routes'
import { adminRoutes } from './admin/admin.routes'
import { PermissionsEnum } from './shared/models/permission-based-access.model'
import { employeesRoutes } from './employees/employees.routes'
import { reportsRoutes } from './reports/reports.routes'

export const routes: Routes = [
  {
    path: '',
    component: LayoutLandingComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./login/components/login/login.component'),
      },
      {
        path: 'two-factor-authentication',
        loadComponent: () => import('./login/components/two-factor-authentication/two-factor-authentication.component'),
      },
      {
        path: 'change-password',
        loadComponent: () => import('./login/components/change-password/change-password.component'),
      },
      {
        path: 'new-pin',
        loadComponent: () => import('./login/components/new-pin/new-pin.component'),
      },
      {
        path: 'company-and-store',
        loadComponent: () => import('./login/components/company-and-store/company-and-store.component'),
      },
      {
        path: 'forgot-password',
        loadComponent: () => import('./login/components/forgot-password/forgot-password.component'),
      },
    ],
  },
  {
    path: 'home',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.home',
      permissionsNeeded: [PermissionsEnum.HOME],
    },
    children: [
      {
        path: '',
        loadComponent: () => import('./home/components/dashboard/dashboard.component'),
      },
    ],
  },
  {
    path: 'sales',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.title',
      permissionsNeeded: [
        PermissionsEnum.CONSOLIDATED_VIEW,
        PermissionsEnum.FISCAL_DOCUMENTS_VIEW,
        PermissionsEnum.CASH_RECONCILIATION_VIEW,
        PermissionsEnum.DEPOSITS_VIEW,
        PermissionsEnum.SAFE_CTRL_INFLOW_VIEW,
        PermissionsEnum.SALES_CLOSURE_VIEW,
        PermissionsEnum.CASH_AUDIT_VIEW,
      ],
    },
    children: salesRoutes,
  },
  {
    path: 'inventory',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.title',
      permissionsNeeded: [
        PermissionsEnum.MOVEMENTS_DAY_VIEW,
        PermissionsEnum.DAILY_INVENTORY_VIEW,
        PermissionsEnum.GENERAL_INVENTORY_VIEW,
        PermissionsEnum.MANUAL_PURCHASE_VIEW,
        PermissionsEnum.RECEIVED_VIEW,
        PermissionsEnum.SENT_VIEW,
        PermissionsEnum.PRODUCT_VIEW,
        PermissionsEnum.RECIPES_VIEW,
        PermissionsEnum.RAW_VIEW,
        PermissionsEnum.COMPLETE_VIEW,
      ],
    },
    children: inventoryRoutes,
  },

  {
    path: 'admin',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.admin.title',
      permissionsNeeded: [
        PermissionsEnum.USER_ADMIN_VIEW,
        PermissionsEnum.ROLE_PERMISSION_VIEW,
        PermissionsEnum.CURRENCY_VIEW,
        PermissionsEnum.ARTICLE_VIEW,
        PermissionsEnum.RESTAURANT_VIEW,
        PermissionsEnum.QCR_GOALS_VIEW,
      ],
    },
    children: adminRoutes,
  },

  {
    path: 'employees',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.employees.title',
      permissionsNeeded: [PermissionsEnum.EMPLOYEE_VIEW],
    },
    children: employeesRoutes,
  },

  {
    path: 'reports',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: {
      breadcrumb: '',
      permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
    },
    children: reportsRoutes,
  },
]
